._1kyflWSsoOeatC79Lkpd5z {
  min-height: 25%;
}

._2dOkL9lUlA63wwhAcBQ-NX {
  background-color: white;
  line-height: 40px;
  padding: 1px 10px 0;
}

._2bRT7KBsw6vxSDn0ZAWHjg {
  background-color: #42526E;
  color: #fff;
}

._2DdKh9ta4EfNqjK_p6iGz5 {
  border: 1px solid #bbb;
  border-radius: 3px;
  padding: 1px 10px 0;
  height: 40px;
  width: 100% !important;
  margin-left: 16px;
  margin-left: 1rem;
}

