._3DSI934mdyjxCC-XFNAhUI {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

._3ZEeRrqdo5zkqz_JSgymuH {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

._3ZEeRrqdo5zkqz_JSgymuH:not(:first-of-type) {
  padding-left: 16px;
  padding-left: 1rem;
}

.sAovRsmEOZMYgcTi2KDch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.sAovRsmEOZMYgcTi2KDch > div {
  position: relative;
  width: 100%;
}

.sAovRsmEOZMYgcTi2KDch input {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100% !important;
  border: 1px solid #bbb;
  border-radius: 3px;
  padding: 1px 10px 0;
  height: 40px;
}

.sAovRsmEOZMYgcTi2KDch label {
  line-height: 40px;
  position: absolute;
  text-align: right;
  right: 24px;
  right: 1.5rem;
  font-weight: normal;
  text-transform: lowercase;
}

.VXz4Z6AuUg4YtANR0ULT5 {
  background-color: white;
  line-height: 40px;
  padding: 1px 10px 0;
}

._1OnuLEz-eYAEbzWmC6Dq_h {
  background-color: #42526e;
  color: #fff;
}

