.c_xiRlJCW82-9_JWfVejJ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 28px;
  line-height: 1.75rem;
  margin-top: 4px;
  margin-top: 0.25rem;
}

.c_xiRlJCW82-9_JWfVejJ label {
  font-weight: normal;
}

.c_xiRlJCW82-9_JWfVejJ div {
  border-color: #bbb !important;
  border-width: 1px;
  background-color: white;
}

@media screen and (max-width: 768px) {
  .c_xiRlJCW82-9_JWfVejJ {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

._17HaNMlSQfrd87f7dSwhvy {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

._17HaNMlSQfrd87f7dSwhvy ._1qsQnumN_86AdXk-O8oKN_ {
  width: 100%;
}

._17HaNMlSQfrd87f7dSwhvy > label {
  width: 100%;
}

._2-NPUOLERerT2R483PO8SQ {
  -ms-flex-preferred-size: content;
      flex-basis: content;
  white-space: nowrap;
  margin-right: 16px;
  margin-right: 1rem;
  margin-bottom: 0 !important;
  line-height: 40px;
  line-height: 2.5rem;
}

._2-NPUOLERerT2R483PO8SQ input[type='checkbox'] {
  margin-right: 12px;
  margin-right: 0.75rem;
  margin-top: 0;
}

._1qsQnumN_86AdXk-O8oKN_ {
  width: 240px;
  width: 15rem;
  height: 40px;
  height: 2.5rem;
  padding: 0;
}

@media screen and (max-width: 768px) {
  ._1qsQnumN_86AdXk-O8oKN_ {
    width: 100%;
  }
}

._30D9WkkIQDXfZXpdIc7dda {
  display: none;
}

