/* Common styles for Markdown rendering */

.markdown-display h1,
.markdown-display h2,
.markdown-display h3,
.markdown-display h4,
.markdown-display h5,
.markdown-display h6,
.markdown-display p,
.markdown-display pre,
.ak-editor-wrapper h1,
.ak-editor-wrapper h2,
.ak-editor-wrapper h3,
.ak-editor-wrapper h4,
.ak-editor-wrapper h5,
.ak-editor-wrapper h6,
.ak-editor-wrapper p,
.ak-editor-wrapper pre {
  margin-bottom: 16px;
  margin-bottom: 1rem;
}

.markdown-display h1:last-child,
.markdown-display h2:last-child,
.markdown-display h3:last-child,
.markdown-display h4:last-child,
.markdown-display h5:last-child,
.markdown-display h6:last-child,
.markdown-display p:last-child,
.markdown-display pre:last-child,
.ak-editor-wrapper h1:last-child,
.ak-editor-wrapper h2:last-child,
.ak-editor-wrapper h3:last-child,
.ak-editor-wrapper h4:last-child,
.ak-editor-wrapper h5:last-child,
.ak-editor-wrapper h6:last-child,
.ak-editor-wrapper p:last-child,
.ak-editor-wrapper pre:last-child {
  margin-bottom: 0;
}

.markdown-display h1,
.ak-editor-wrapper h1 {
  font-size: 29px;
  font-weight: 600;
}

.markdown-display h2,
.ak-editor-wrapper h2 {
  font-size: 24px;
  font-weight: 500;
}

.markdown-display h3,
.ak-editor-wrapper h3 {
  font-size: 20px;
  font-weight: 500;
}

.markdown-display h4,
.ak-editor-wrapper h4 {
  font-size: 16px;
  font-weight: 600;
}

.markdown-display h5,
.ak-editor-wrapper h5 {
  font-size: 14px;
  font-weight: 600;
}

.markdown-display h6,
.ak-editor-wrapper h6 {
  color: #5e6c84;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.markdown-display h1,
.markdown-display h2,
.markdown-display h3,
.markdown-display h4,
.markdown-display h5,
.markdown-display h6,
.ak-editor-wrapper h1,
.ak-editor-wrapper h2,
.ak-editor-wrapper h3,
.ak-editor-wrapper h4,
.ak-editor-wrapper h5,
.ak-editor-wrapper h6 {
  line-height: 1.5;
}

.markdown-display h1:first-child,
.markdown-display h2:first-child,
.markdown-display h3:first-child,
.markdown-display h4:first-child,
.markdown-display h5:first-child,
.markdown-display h6:first-child,
.ak-editor-wrapper h1:first-child,
.ak-editor-wrapper h2:first-child,
.ak-editor-wrapper h3:first-child,
.ak-editor-wrapper h4:first-child,
.ak-editor-wrapper h5:first-child,
.ak-editor-wrapper h6:first-child {
  margin-top: 0;
}

.markdown-display ul,
.markdown-display ol,
.ak-editor-wrapper ul,
.ak-editor-wrapper ol {
  margin-bottom: 16px;
  margin-bottom: 1rem;
  margin-left: 20px;
  padding-left: 0 !important;
}

.markdown-display ul:last-child,
.markdown-display ol:last-child,
.ak-editor-wrapper ul:last-child,
.ak-editor-wrapper ol:last-child {
  margin-bottom: 0;
}

.markdown-display ul,
.ak-editor-wrapper ul {
  list-style: disc;
}

.markdown-display ol,
.ak-editor-wrapper ol {
  list-style: decimal;
}

.markdown-display blockquote:before,
.markdown-display q:before,
.ak-editor-wrapper blockquote:before,
.ak-editor-wrapper q:before {
  content: '\201C';
}

.markdown-display blockquote:after,
.markdown-display q:after,
.ak-editor-wrapper blockquote:after,
.ak-editor-wrapper q:after {
  content: '\201D';
}

.markdown-display blockquote,
.ak-editor-wrapper blockquote {
  padding-left: 40px;
  border: none;
  margin-bottom: 16px;
  margin-bottom: 1rem;
}

.markdown-display blockquote:before,
.ak-editor-wrapper blockquote:before {
  float: left;
  margin-left: -1em;
  text-align: right;
  width: 1em;
}

.markdown-display blockquote > :last-child,
.ak-editor-wrapper blockquote > :last-child {
  display: inline-block;
}

.markdown-display .code-content pre,
.ak-editor-wrapper .code-content pre {
  border: none;
  padding: 0;
}

.markdown-display pre,
.ak-editor-wrapper pre {
  border-radius: 4px;
  font-family: Menlo, Monaco, 'Courier New', monospace;
  background-color: #f5f5f5;
  border: 1px solid #e1e1e1;
  padding: 8px;
  padding: 0.5rem;
  white-space: pre-wrap;
}

.markdown-display pre code,
.ak-editor-wrapper pre code {
  padding: 0px;
  color: inherit;
  background-color: transparent;
  border: none;
}

.markdown-display table,
.ak-editor-wrapper table {
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 100%;
  border-collapse: separate;
  max-width: 100%;
  border-spacing: 0;
  table-layout: fixed;
}

.markdown-display table th,
.markdown-display table td,
.ak-editor-wrapper table th,
.ak-editor-wrapper table td {
  padding: 16px;
  padding: 1rem;
  line-height: 16px;
  line-height: 1rem;
  text-align: left;
  word-wrap: break-word;
}

.markdown-display table th,
.ak-editor-wrapper table th {
  font-weight: 700;
  vertical-align: bottom;
  font-weight: 500;
}

.markdown-display table th:first-of-type,
.ak-editor-wrapper table th:first-of-type {
  border-radius: 4px 0 0 0;
}

.markdown-display table th:last-of-type,
.ak-editor-wrapper table th:last-of-type {
  border-radius: 0 4px 0 0;
}

.markdown-display table td,
.ak-editor-wrapper table td {
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.markdown-display table th + th,
.markdown-display table td + td,
.ak-editor-wrapper table th + th,
.ak-editor-wrapper table td + td {
  border-left: 1px solid #ddd;
}

.markdown-display table tr:last-of-type td:first-of-type,
.ak-editor-wrapper table tr:last-of-type td:first-of-type {
  border-radius: 0 0 0 4px;
}

.markdown-display table tr:last-of-type td:last-of-type,
.ak-editor-wrapper table tr:last-of-type td:last-of-type {
  border-radius: 0 0 4px 0;
}

