._37dhPcwUWNUMfjKk238SpJ {
  position: relative;
}

._37dhPcwUWNUMfjKk238SpJ input {
  height: 40px;
  padding-left: 48px;
  padding-left: 3rem;
  border: 1px solid #b0b0b0;
  border-radius: 0.25rem;
  width: 100% !important;
}

._37dhPcwUWNUMfjKk238SpJ td[aria-selected="true"] div {
  background-color: #2ecc71;
  color: white;
}

._1ynIwyU7HA1N84_llUl_0j {
  position: absolute;
  display: block;
  left: 0;
  width: 40px;
  line-height: 40px;
  text-align: center;
  border-right: 1px solid #b0b0b0;
}

._2WNUopTqqg2FWW8BTuqHMR {
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, .075);
          box-shadow: 0 2px 5px rgba(0, 0, 0, .075);
  border: 1px solid #d0d0d0;
  margin-top: 4px;
  margin-top: 0.25rem;
  margin-left: -2px;
  margin-left: -0.125rem;
  border-radius: 0.25rem;
  /* NASTY HACK TO DIG INTO ATLASKIT COMPONENT */
  /* NASTY HACK TO DIG INTO ATLASKIT COMPONENT */
}

._2WNUopTqqg2FWW8BTuqHMR > div {
  border-radius: inherit;
}

._2WNUopTqqg2FWW8BTuqHMR > div > div {
  border-radius: inherit;
}

